@import "minireset.css/minireset";

html,
body {
    height: 100%;
    font-family: Prompt;
    width: 100%;
}

body {
    margin: 0;
    font-family: Prompt;
}

span,
a,
button,
div {
    font-family: Prompt;
}

@font-face {
    font-family: "Prompt";
    src: url("./assets/font/Prompt/Prompt-Regular.eot");
    src: url("./assets/font/Prompt/Prompt-Regular.eot?#iefix") format("embedded-opentype"),
        url("./assets/font/Prompt/Prompt-Regular.woff2") format("woff2"),
        url("./assets/font/Prompt/Prompt-Regular.woff") format("woff"),
        url("./assets/font/Prompt/Prompt-Regular.ttf") format("truetype"),
        url("./assets/font/Prompt/Prompt-Regular.svg#Prompt-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.header-navbar {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 25%;
    align-content: flex-start;
    margin: 0px;
    min-width: 280px;
}

.igx-drop-down__list-scroll {
    .igx-display-container {
        width: fit-content;
        min-width: -webkit-fill-available;
        overflow: hidden;
    }

    .igx-combo__content {
        overflow: auto !important;
        max-height: none !important;
    }
}

.igx-grid__th-group-title {
    color: #81817f
}

/* .e-commerce-expansion-panel .igx-expansion-panel__header-inner {
    padding: 0px 10px 0px 0px;
    background: transparent;
}

.e-commerce-expansion-panel .igx-expansion-panel__body {
    padding: 0px 0px 0px 20px;
    background: transparent;
    margin-top: -10px;
} */
@import "@mobiscroll/angular/dist/css/mobiscroll.scss";